import FormControl from "@material-ui/core/FormControl/FormControl"
import {sortBy} from 'lodash'
import React from "react"
import Select from 'react-select';
import {useSetTranslation} from "common/useSetTranslation";
import { useTranslation } from "react-i18next";


const customStyles = {
  control: (providedStyle) => ({
    ...providedStyle,
    border: 'none',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1) inset',
    height: 56
  }),
};


const SingleSelectDropdown = ({DropDownItems, onAnswered, value}) => {
  const {t}  = useTranslation();
  const {st} = useSetTranslation();

  const handleChoice = (e) => {
    onAnswered(e);
  };
  const orderedOptionItems = sortBy(DropDownItems, (option) => option.displayOrder);

  return (
    <FormControl
      style={{display: 'flex'}}>
      <Select
        isClearable={true}
        onChange={handleChoice}
        value={value}
        styles={customStyles}
        getOptionValue={option => option.key}
        getOptionLabel={option => (st(option.displayName || option.label, option.displayName || option.localeLabel))}
        options={orderedOptionItems}
        placeholder={t('selectCaps')}
        noOptionsMessage={() => t('noOptions')}
      />
    </FormControl>
  );
};


export default (SingleSelectDropdown);
