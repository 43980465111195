import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {PAGE_HEIGHT_WITH_NAVBAR, PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER} from "common/helpers";
import SurveyNavigation from "components/SurveyNavigation";
import {difference, filter, isEmpty} from 'lodash';
import React, {useEffect} from 'react';
import {Route} from "react-router-dom";
import SurveyCardListing from "views/survey/SurveyCardListing";
import '../common/styles/animate.css';
import {useTranslation} from "react-i18next";

const MaterialStyles = theme => ({
  learningHubWrapper: {
    background: `linear-gradient(180deg, rgba(224, 179, 52, 0.2) 0%, rgba(224, 179, 52, 0) 100%)`,
    height: PAGE_HEIGHT_WITH_NAVBAR,
    padding: '30px 0px'
  },
  paper: {
    height: '60vh',
    width: '100%',
  },
  pageWrapper: {
    height: PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER,
    overflowY: 'auto'
  },
});

const SurveysListing = ({classes, displayName, getAllSurveys, surveys, setCurrentPath}) => {
  const {t} = useTranslation();

  useEffect(() => {
    getAllSurveys();
    setCurrentPath('survey');
  }, []);


  const usableSurveys = filter(surveys, survey => survey.expPermission === 'use');
  const completedSurveys = filter(usableSurveys, survey => survey.status.startsWith('COMPLETE'));
  const incompleteSurveys = difference(usableSurveys, completedSurveys);

  return (
    <div className={classes.learningHubWrapper}>
      <Container>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={4} md={2}>
            <SurveyNavigation displayName={displayName}/>
          </Grid>
          <Grid item xs={12} sm={8} md={10}>
            <Route exact path={"/surveylisting"}>
              <div className={classes.pageWrapper}>
                {!isEmpty(incompleteSurveys) &&
                <SurveyCardListing title={t('pendingSurvey')} surveys={incompleteSurveys}/>}
                {!isEmpty(completedSurveys) &&
                <SurveyCardListing title={t('completedSurvey')} surveys={completedSurveys}/>}
              </div>
            </Route>
            <Route path={"/surveylisting/pendingsurveys"}>
              {!isEmpty(incompleteSurveys) && <SurveyCardListing title={t('pendingSurvey')} surveys={incompleteSurveys}/>}
            </Route>
            <Route path={"/surveylisting/completedsurveys"}>
              {!isEmpty(completedSurveys) && <SurveyCardListing title={t('completedSurvey')} surveys={completedSurveys}/>}
            </Route>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

export default withStyles(MaterialStyles)(SurveysListing);
