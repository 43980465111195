import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import Avatar from "@material-ui/core/Avatar/Avatar";
import * as PropTypes from "prop-types";
import classNames from "classnames";
import Badge from "@material-ui/core/Badge/Badge";
import {get} from 'lodash';
import {getPartner} from "common/helpers";

const styles = theme => ({
  ListMemberWrapper: {
    display: 'flex',
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.15)',
    padding: '12px 16px',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background 1s',
    '&:hover': {
      background: "#EEEEEE",
    },
  },
  mediumAvatar: {
    width: 48,
    height: 48
  },
  smallAvatar: {
    width: 32,
    height: 32
  },
  groupAvatar: {
    width: 34,
    height: 34
  },
  name: {
    marginLeft: 12,
    fontSize: 14,
    textTransform: 'capitalize'
},
  nameUnread:{
    fontWeight: 'bold'
  },
  unreadMessageCount: {
    backgroundColor: '#E0B334',
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '2px 8px',
    borderRadius: '50%',
    marginLeft: 'auto',
  },
  unreadTaggedMessageIcon: {
    backgroundColor: '#E0B334',
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '1px 5px',
    borderRadius: '60%',
    marginRight: 2,
  },
  groupAvatarCount: {
    backgroundColor: '#000',
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
  },
  badgePosition: {
    right: '0%',
    bottom: '-8%'
  },
  groupWrapper:{
    padding:'0px 0px 18px 0px',
    width:48
  },
  searchChannelTitle: {
    color: '#000000',
    fontWeight: 500,
    fontSize: 14
  },
  searchResult: {
    borderBottom: 'none',
    padding: '4px 16px',
  },
  selectedSearchedChannel: {
    color: '#E0B334',
  },
  highlight: {
    color: "#3E3E3E",
    fontWeight: 600,
    fontSize: 12,
  },
});


const ListMember = ({
                      classes, isGroup, members, myId, channelName, channelId, onItemClick,
                      unreadMessageCount, isSelected, isSearch, setSelectedTab, unreadMessageData
                    }) => {

  const partner = !isGroup ? getPartner(members,myId) : "";

  const isUnread = unreadMessageCount > 0;

  return (
    <div className={classNames(classes.ListMemberWrapper, isSearch && classes.searchResult)} style={{ backgroundColor: (isSelected && !isSearch) ? '#EEEEEE' : '' }}
         onClick={() => {
          onItemClick(channelId, 1)
          setSelectedTab && setSelectedTab('channels')
        }}>
      <div>
        {isGroup &&
        <div className={classes.groupWrapper}>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            classes={{
              anchorOriginBottomRightCircle: classes.badgePosition
            }}
            badgeContent={<Avatar alt="" src={get(members,"[0].displayPicture")}  className={classes.groupAvatar}/>}
          >
            <Avatar alt=""  className={classes.smallAvatar} classes={
              {root: classes.groupAvatarCount}
            }>+{members.length - 2}</Avatar>
          </Badge>
        </div>
        }
        {!isGroup && <Avatar alt={get(partner, "userFullName")} src={get(partner,'displayPicture')} className={classes.mediumAvatar}/>}
      </div>
      {!isGroup &&
      <div
        className={classNames(classes.name, isUnread && classes.nameUnread, isSearch && classes.searchChannelTitle, (isSearch && isSelected) && classes.selectedSearchedChannel)}>{get(partner, "userFullName")}</div>}
      {isGroup &&
      <div className={classNames(classes.name, isUnread && classes.nameUnread, isSearch && classes.searchChannelTitle, (isSearch && isSelected) && classes.selectedSearchedChannel)}>{channelName}</div>}
      <div style={{ display: 'flex', marginLeft: 'auto' }}>
        {isUnread && unreadMessageData?.is_tagged && <div 
          className={classes.unreadTaggedMessageIcon} 
        >@</div>
        }
        {isUnread &&
        <div className={classes.unreadMessageCount}>{unreadMessageCount}</div>
        }
      </div>
    </div>
  );
};

export default withStyles(styles)(ListMember);


ListMember.propTypes = {
  isGroup: PropTypes.bool,
  isUnread: PropTypes.bool,
  isSelected: PropTypes.bool,
  channelId: PropTypes.string,
  onItemClick: PropTypes.func,
  unreadMessageCount: PropTypes.number
};

ListMember.defaultProps = {
  isGroup: false,
  isUnread: false,
  channelId: ''
};
