import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {PAGE_HEIGHT_WITH_NAVBAR} from "common/helpers";
import SwipeCards from "components/SwipeCards";
import UnlockMentorshipStatusHomePageCard from "components/UnlockStatusCard";
import {get, isUndefined, padStart} from "lodash";
import React, {useEffect} from 'react';
import '../../common/styles/animate.css';
import { useTranslation } from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";


const MaterialStyles = theme => ({
  learningHubWrapper: {
    height: PAGE_HEIGHT_WITH_NAVBAR,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#E5E5E5',
    [theme.breakpoints.down('xs')]: {
      height: PAGE_HEIGHT_WITH_NAVBAR,
      paddingTop: 16,
      justifyContent: 'flex-start',
    },
  },
  statusCardWrapper: {
    [theme.breakpoints.down('xs')]: {
      order: 1,
      marginTop: '0px'
    },
  }
});


const isApplicationInReview = (status) => get(status, 'experiences.matchingflow') === "use" && get(status, 'experiences.matchingflowStatus.applicationReview') === "use";


const InactiveMentorshipLearning = ({classes, mentorshipCards, ruleMessage, getMentorshipCards, status}) => {

  useEffect(() => {
    getMentorshipCards();
  }, []);

  const {t} = useTranslation();
  const {st} = useSetTranslation();

  const mentorshipSessionStatus = get(status, 'experiences.mentorshipSessionStatus');
  const message = isApplicationInReview(status) ?
    st(get(ruleMessage, 'applicationReview'), get(ruleMessage, 'localeApplicationReview')) : st(get(ruleMessage, "curriculum"), get(ruleMessage, "localeCurriculum"));

  const completedSessions = get(mentorshipSessionStatus, 'completedSessions');
  const remainingSessions = get(mentorshipSessionStatus, 'remainingSessions');

  const dataExist = !isUndefined(completedSessions) && !isUndefined(remainingSessions);
  const countMessage = dataExist ? `${padStart(completedSessions, 2, "0")}/${padStart((completedSessions + remainingSessions), 2, "0")}` : "";

  return (
    <div className={classes.learningHubWrapper}>
      <Container>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={4} md={4} className={classes.statusCardWrapper}>
            <UnlockMentorshipStatusHomePageCard
              message={message}
              count={countMessage}
              type={t('mentorship')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <SwipeCards cards={mentorshipCards}/>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

export default withStyles(MaterialStyles)(InactiveMentorshipLearning);
