import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import * as propTypes from "prop-types";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from "classnames";
import {VideoButton} from "components/Chat/VideoButton";
import {history} from "common/history";
import ExpandIcon from "common/assets/ExpandIcon.svg";
import RestoreIcon from "common/assets/RestoreIcon.svg";
import CircularIcon from "components/CircularIcon";
import {isEmpty, noop, truncate} from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton/IconButton";

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    height: 64,
    backgroundColor: '#E0B334',
    display: 'flex',
    alignItems: 'center',
    padding: 16,
  },
  fullScreenWrapper: {
    height: 45,
  },
  windowTitleText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '16px',
    textTransform: 'UPPERCASE'
  },
  titlePrefixText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '16px',
  },
  subTitleText: {
    color: 'rgba(0, 0, 0, 0.51)',
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '14px',
    marginTop: 8,
    cursor: 'pointer',
  },
  fullScreenSubTitle: {
    marginTop: 0,
    marginLeft: 12
  }
});


const MessageWindowHeader = ({
                               classes, windowTitle, subTitle, onBackPress, showExpandBtn, onExpandBtnPress = noop,
                               showVideoBtn, isFullScreen, onSubTitleClick = noop, channel, showFullScreenButton,
                               onFullScreenRequested, fullScreenVideo, titlePrefix, isBlackOutPeriodActive, showCloseBtn,
                               closeBtnAction,showBackBtn
                             }) => {
  return (
    <div className={classNames(classes.wrapper, isFullScreen && classes.fullScreenWrapper)}>
      {showBackBtn && <div><ArrowBackIcon style={{color: '#fff', cursor: 'pointer'}} onClick={onBackPress}/></div>}
      <div style={{display: "flex", flexDirection: isFullScreen ? 'row' : 'column', marginLeft: 16}}>
        {!isEmpty(titlePrefix) && <div className={classes.titlePrefixText}>{titlePrefix}&nbsp;</div>}
        <div
          className={classes.windowTitleText}>{isFullScreen ? windowTitle : truncate(windowTitle, {length: 16})}</div>
        <div className={classNames(classes.subTitleText, isFullScreen && classes.fullScreenSubTitle)}
             onClick={onSubTitleClick}
        >{subTitle}</div>
      </div>
      <div style={{display: 'flex', marginLeft: 'auto', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
        {showVideoBtn && <VideoButton channel={channel}
                                      isBlackedOut={isBlackOutPeriodActive}
                                      onClick={() => {
                                        history.push(`/chat/messenger?section=video`)
                                      }}
        />}
        {showFullScreenButton &&
        <CircularIcon onClick={onFullScreenRequested}>
          <img src={fullScreenVideo ? RestoreIcon : ExpandIcon} alt={""} />
        </CircularIcon>}
        {(showExpandBtn && !isFullScreen) &&
        <ExpandMoreIcon style={{cursor: 'pointer'}} onClick={onExpandBtnPress}/>
        }
        {
          showCloseBtn &&
          <IconButton edge={'end'}
                      onClick={closeBtnAction}>
            <CloseIcon style={{color: '#000'}}/>
          </IconButton>
        }
      </div>
    </div>
  );
};

export default withStyles(styles)(MessageWindowHeader);


MessageWindowHeader.propTypes = {
  windowTitle: propTypes.string.isRequired,
  subTitle: propTypes.string,
  showExpandBtn: propTypes.bool,
  onExpandBtnPress: propTypes.func,
  onBackPress: propTypes.func,
  isFullScreen: propTypes.bool,
  isBlackOutPeriodActive: propTypes.bool,
  showCloseBtn: propTypes.bool,
  showBackBtn:propTypes.bool,
  closeBtnAction: propTypes.func
};

MessageWindowHeader.defaultProps = {
  showVideoBtn: true,
  showExpandBtn: true,
  isFullScreen: false,
  showBackBtn:false
};
