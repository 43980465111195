import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {PAGE_HEIGHT_WITH_NAVBAR} from "common/helpers";
import LearningHubNavigation from "components/LearningHubNavigation";
import AllSessionsContainer from "containers/AllSessionsContainer";
import BookmarkedSessionsContainer from "containers/BookmarkedSessionsContainer";
import CuratedSessionsContainer from "containers/CuratedSessionsContainer";
import FeedContainer from "containers/FeedContainer";
import OnGoingSessionsContainer from "containers/OnGoingSessionsContainer";
import SessionContainer from "containers/SessionContainer";
import React, {useEffect} from 'react';
import {Route} from "react-router-dom";
import '../common/styles/animate.css';


const MaterialStyles = theme => ({
  learningHubWrapper: {
    background: `linear-gradient(180deg, rgba(224, 179, 52, 0.2) 0%, rgba(224, 179, 52, 0) 100%)`,
    height: PAGE_HEIGHT_WITH_NAVBAR,
    padding: '30px 0px'
  },
  paper: {
    height: '60vh',
    width: '100%',
  }
});

const LearningHub = ({classes, displayName, mentorshipSessionStatus, setCurrentPath}) => {
  useEffect(() => {
    setCurrentPath('learninghub');
  }, []);

  return (
    <div className={classes.learningHubWrapper}>
      <Container>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={4} md={2}><LearningHubNavigation mentorshipSessionStatus={mentorshipSessionStatus}
                                                                  displayName={displayName}/></Grid>
          <Route exact path={"/learninghub"}>
            <React.Fragment>
              <Grid item xs={12} sm={8} md={6}><SessionContainer/></Grid>
              <Grid item xs={12} sm={12} md={4}
                    style={{paddingBottom: 0, display: 'flex', justifyContent: 'center'}}><FeedContainer/></Grid>
            </React.Fragment>
          </Route>
          <Route path={"/learninghub/allsession"}>
            <Grid item xs={12} sm={8} md={10}><AllSessionsContainer/></Grid>
          </Route>
          <Route path={"/learninghub/curatedsession"}>
            <Grid item xs={12} sm={8} md={10}><CuratedSessionsContainer/></Grid>
          </Route>
          <Route path={"/learninghub/bookmarkedsessions"}>
            <Grid item xs={12} sm={8} md={10}><BookmarkedSessionsContainer/></Grid>
          </Route>
          <Route path={"/learninghub/ongoingsessions"}>
            <Grid item xs={12} sm={8} md={10}><OnGoingSessionsContainer/></Grid>
          </Route>
        </Grid>
      </Container>
    </div>
  )
};

export default withStyles(MaterialStyles)(LearningHub);
