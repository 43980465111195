import QuestionErrorMessage from "components/Question/QuestionErrorMessage";
import {isEmpty} from 'lodash';
import React from 'react';
import QuestionDetails from "./QuestionDetails";
import QuestionHelpText from "./QuestionHelpText";
import QuestionLabel from "./QuestionLabel";
import {useSetTranslation} from "common/useSetTranslation";


const styles = {
  questionContainer: {
    marginBottom: 12
  }
};


const QuestionWrapper = ({error, question, children}) => {
  const {st} = useSetTranslation();
  return (
    <div style={styles.questionContainer}>
      <QuestionDetails
        title={st(question.title, question.localeTitle)}
        isLabelEmpty={isEmpty(question.label)}
        mandatory={question.mandatory}
      />
      <QuestionLabel
        label={st(question.label, question.localeLabel)}
        localLabel={question.localLabel}
        mandatory={question.mandatory}
      />

      {children}

      <QuestionErrorMessage error={error}/>
      <QuestionHelpText helpText={st(question.helpText, question.localeHelpText)}/>
    </div>
  );
};

export default QuestionWrapper;
