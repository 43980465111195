import {connect} from 'react-redux';
import {callEnded, joinVideo, logVideoEvent} from "actions/videoAction";
import {VideoFrame} from "components/Chat/VideoFrame";

const mapStateToProps = ({
                           video,
                           profile: {displayName},
                           auth: {user: {id}}
                         }) => ({
  video,
  displayName,
  myId: id
});

export default connect(mapStateToProps, {
  callEnded,
  joinVideo,
  logVideoEvent
})(VideoFrame);
