import {filter, keyBy, map, merge, values} from "lodash";

const prefix = '[CHAT]';

export const actions = {
  STORE_CHANNEL_LIST: `${prefix} STORE_CHANNEL_LIST`,
  CHANNEL_API_ACTION: `${prefix} CHANNEL_API_ACTION`,
  SET_ERROR: `${prefix} SET_ERROR`,
  SET_MESSAGE_WINDOW: `${prefix} SET_MESSAGE_WINDOW`,
  ADD_MESSAGES: `${prefix} ADD_MESSAGES`,
  ADD_NEW_MESSAGE_TO_CHANNEL: `${prefix} ADD_NEW_MESSAGE_TO_CHANNEL`,
  UPDATE_UNREAD_COUNT: `${prefix} UPDATE_UNREAD_COUNT`,
  CLEAR_MESSAGES: `${prefix} CLEAR_MESSAGES`,
  ADD_CHAT_BLACKOUT_PERIOD: `${prefix} ADD_CHAT_BLACKOUT_PERIOD`,
  STORE_DEVICE_TOKEN: `${prefix} STORE_DEVICE_TOKEN`,
  STORE_CHANNEL_MEMBERS_LAST_VIEWED_TIME: `${prefix} STORE_CHANNEL_MEMBERS_LAST_VIEWED_TIME`,
  MESSAGE_SEEN: `${prefix} MESSAGE_SEEN`,
  UPDATE_LAST_VIEWED_TIME: `${prefix} UPDATE_LAST_VIEWED_TIME`,
  STORE_BOT_USERS: `${prefix} STORE_BOT_USERS`,
};

const initChat = {
  chatChannels: {},
  fetchingList: false,
  error_fetching_members: false,
  messageWindowActive: false,
  selectedChannelId: '',
  messages: {},
  channelUnreadCount: [],
  nextPageNumber: 1,
  channelsWithMemberId: [],
  chatBlackoutPeriod: [],
  isUserMessageSeen: false,
  botUsers: [],
};

export default (state = initChat, action) => {
  switch (action.type) {
    case actions.STORE_CHANNEL_LIST:
      const individualChannels = filter(action.payload.channels, channel => !channel.isGroup);
      const channelsWithMember = map(individualChannels, channel => {
        return ({
          ...channel,
          userId: filter(channel.channelMembers, member => member.userId !== action.payload.myId)[0].userId
        })
      });

      return {
        ...state,
        chatChannels: action.payload.channels,
        channelsWithMemberId: channelsWithMember
      };
    case actions.CHANNEL_API_ACTION:
      return {
        ...state,
        fetchingList: action.payload
      };
    case actions.SET_ERROR:
      return {
        ...state,
        error_fetching_members: action.payload
      };
    case actions.SET_MESSAGE_WINDOW:
      const selectedChannel = action.payload.state ? action.payload.channelId : '';
      return {
        ...state,
        messageWindowActive: action.payload.state,
        selectedChannelId: selectedChannel
      };
    case actions.ADD_MESSAGES:
      const formattedMessages = keyBy(action.payload.messages, 'msg_id');
      const appendMessages = action.payload.type === 'searched-message' ? formattedMessages : (action.payload.nextPageNumber === state.nextPageNumber ? state.messages : {
        ...state.messages,
        ...formattedMessages
      });
      return {
        ...state,
        isUserMessageSeen: false,
        messages: appendMessages,
        nextPageNumber: action.payload.nextPageNumber
      };
    case actions.ADD_NEW_MESSAGE_TO_CHANNEL:
      const messages = {...state.messages}
      const messageId = action.payload.msg_id;
      const tempId = action.payload.temp_id;
      delete messages[tempId]
      return {
        ...state,
        isUserMessageSeen: false,
        messages: {...messages, [messageId]: action.payload}
      };
    case actions.UPDATE_UNREAD_COUNT:
      return {
        ...state,
        isUserMessageSeen: false,
        channelUnreadCount: values(merge(keyBy(state.channelUnreadCount, 'channelId'), keyBy(action.payload, 'channelId')))
      };
    case actions.CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
        nextPageNumber:1
      };
    case actions.ADD_CHAT_BLACKOUT_PERIOD:
      return {
        ...state,
        chatBlackoutPeriod: action.payload,
      };
    case actions.STORE_DEVICE_TOKEN:
      return {
        ...state,
        deviceToken: action.payload,
      };
    case actions.STORE_CHANNEL_MEMBERS_LAST_VIEWED_TIME:
      const individualChannelsList = filter(action.payload.channels, channel => !channel.isGroup);
      const channelsWithMembers = map(individualChannelsList, channel => {
        return ({
          ...channel,
          userId: filter(channel.channelMembers, member => member.userId !== action.payload.myId)[0].userId,
        });
      });
      return {
        ...state,
        chatChannels: action.payload.channels,
        channelsWithMemberId: channelsWithMembers,
      };
    case actions.MESSAGE_SEEN:
      return {
        ...state,
        isUserMessageSeen: true,
      };
    case actions.UPDATE_LAST_VIEWED_TIME:
      const channelId = action.payload.channelId
      const newTimestamp = action.payload.lastViewedTime
      const userId = action.payload.userId
      const updatedChannelData = action.payload.chatChannels.map(channel => {
        if (channel.channelId === channelId) {
          channel.channelMembers.forEach(member => {
            if (member.userId === userId) {
              member.lastViewedTimestamp = newTimestamp;
            }
          });
        }
        return channel
      });
  
      return {
        ...state,
        chatChannels: updatedChannelData,
        channelsWithMemberId: updatedChannelData,
      };

    case actions.STORE_BOT_USERS:
      return {
        ...state,
        botUsers: action.payload.chatBotUsers
      }

    default:
      return state
  }
}
